<template>
  <div>
    <Header navName="anli"></Header>
    <!-- PC端 -->
    <div class="anli_PC" v-if="!$isAndroid">
      <!-- banner -->
      <div class="banner">
        <img src="@/static/anli/banner.png" />
      </div>

      <div class="case_con">
        <div class="case_con_list" v-for="(item, index) in anList" :key="index">
          <div class="case_con_list_img">
            <img :src="require(`@/static/anli/${item.icon}`)" :alt="item.name">
          </div>
          <div class="case_con_list_text">
            <p class="name">{{ item.name }}</p>
            <p class="con">{{ item.con }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="anli_H5" v-else>
      <!-- banner -->
      <div class="banner">
        <img src="@/static/anli/banner.png" />
      </div>

      <div class="cases">
        <div class="case_list" v-for="(item, index) in anList" :key="index">
          <div class="case_list_img" :style="{ backgroundImage: 'url(' + require(`@/static/anli/${item.icon}`) + ')' }">
          </div>
          <div class="case_list_bottom">
            <div class="case_list_title">{{ item.name }}</div>
            <div class="case_list_introduce">{{ item.con }}</div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      anList: [
        {
          icon: 'a1.jpg',
          name: 'S2B2C礼品商城',
          con: '线上购买礼品赠送好友系统'
        },
        {
          icon: 'a2.jpg',
          name: '极砚OA',
          con: ''
        },
        {
          icon: 'a3.jpg',
          name: '测试',
          con: ''
        },
        {
          icon: 'a4.jpg',
          name: '测试',
          con: ''
        },
        {
          icon: 'a5.jpg',
          name: '测试',
          con: ''
        },
        {
          icon: 'a6.jpg',
          name: '测试',
          con: ''
        }
      ]
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang='less'>
.anli_PC {
  .banner {
    img {
      width: 100%;
      height: 370px;
      object-fit: cover;
      object-position: center center;
    }
  }

  .case_con {
    width: 1245px;
    margin: 0 auto;
    max-height: 1727px;
    min-height: 700px;
    margin-top: -12px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .case_con_list {
      width: 370px;
      height: 400px;
      background-color: #ffffff;
      margin: 50px 22.5px 0 22.5px;
      padding-top: 5px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      }

      .case_con_list_img {
        width: 360px;
        height: 283px;
        margin: 0 auto 5px auto;

        img {
          width: 360px;
          height: 283px;
        }
      }

      .case_con_list_text {
        margin-left: 25px;
        margin-top: 20px;

        .name {
          font-size: 20px;
          color: #222222;
        }

        .con {
          font-size: 16px;
          color: #666666;
          margin-top: 8px;
          width: 320px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

}

.anli_H5 {

  .banner {
    img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 132px;
    }
  }

  .cases {
    width: 100%;
    padding: 1px .4rem;
    box-sizing: border-box;

    .case_list {
      width: 100%;
      height: 7.21rem;
      margin: .4rem 0;
      border: 1px solid rgba(225, 225, 225, 1);
      border-radius: .1rem;

      &:nth-child(1) {
        margin: .65rem 0 .4rem;
      }

      .case_list_img {
        width: 100%;
        height: 5.75rem;
        background-size: 100%;
        background-position: center;
      }

      .case_list_bottom {
        width: 9.2rem;
        height: 1.47rem;
        border: 0 solid rgba(225, 225, 225, 1);
        align-items: center;
        padding: 0 .4rem;
        box-sizing: border-box;
        justify-content: space-between;
        display: flex;

        .case_list_title {
          font-size: .37rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(40, 40, 44, 1);
          line-height: .37rem;
          margin-bottom: .2rem;
        }

        .case_list_introduce {
          font-size: .32rem;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: rgba(102, 102, 102, 1);
          line-height: .32rem;
        }
      }
    }






  }

}
</style>